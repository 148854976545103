<template>
  <BRow>
    <BCol lg="6">
      <BCard class="border rounded-lg">
        <div class="flex items-center justify-between">
          <div class="text-black text-xl">
            Performa Ongkir Ekspedisi
          </div>
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/arrow-square-right.svg"
            alt="Komerce"
            class="cursor-pointer"
            @click="$router.push('/performa-ekspedisi')"
          >
        </div>
        <BRow class="justify-end my-1">
          <BFormSelect
            v-model="expedition"
            :options="expeditionList"
            value-field="shipping_name"
            text-field="shipping_name"
            placeholder="Pilih Status"
            class="w-auto mr-[5px]"
          />
          <MonthlyPicker
            v-model="filter.delivery"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            date-format="MMM YYYY"
          />
        </BRow>
        <BOverlay
          :show="loading.chart"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <ApexChart
            type="area"
            :options="optionChartShipping"
            :series="seriesChartShipping"
          />
        </BOverlay>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="border rounded-lg mb-0 ">
        <div class="text-black text-xl">
          Top Ongkir Ekspedisi
        </div>
        <BRow class="justify-end my-1">
          <BFormSelect
            v-model="payment"
            :options="optionExpedition"
            value-field="value"
            text-field="text"
            placeholder="Pilih Tipe Pengiriman"
            class="w-auto mr-[5px]"
          />
          <MonthlyPicker
            v-model="filter.shipping"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            date-format="MMM YYYY"
          />
        </BRow>
        <div class="flex flex-col justify-between">
          <BOverlay
            :show="loading.shipping"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="items"
              :fields="fields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              sticky-header
            >
              <template #cell(detail)="data">
                <BButton
                  v-if="handleDetailButton(data.item.shipping)"
                  variant="outline-primary"
                  size="sm"
                  @click="$router.push({
                    name: 'top-ongkir-detail',
                    params: { ekspedisi: data.item.shipping.toLowerCase(), payment: payValueDetail, filterDate: filter.shipping },
                  })"
                >
                  Detail
                </BButton>
              </template>
              <template #cell(shipping_cost)="data">
                <span class="text-[#34a770]">{{
                  IDR(data.item.shipping_cost)
                }}</span>
              </template>
            </BTable>
          </BOverlay>
          <BCard class="border rounded-lg mb-0">
            <div
              v-if="loading.shipping"
              class="d-flex justify-content-center"
            >
              <BSpinner
                small
                variant="primary"
              />
            </div>
            <BRow v-else>
              <BCol lg="6">
                <div class="flex">
                  <div class="mr-1 text-black font-bold">
                    Total
                  </div>
                  <img
                    v-b-tooltip.hover.top="'Total order / nilai ongkir di bulan tersebut'"
                    src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                    alt="Komerce"
                    class="cursor-pointer"
                  >
                </div>
              </BCol>
              <BCol
                lg="6"
                class="p-0"
              >
                <BRow class="justify-between">
                  <BCol lg="3">
                    <span class="font-bold">{{ totalOrder }}</span>
                  </BCol>
                  <BCol lg="7">
                    <span class="font-bold">{{ IDR(totalShipping) }}</span>
                  </BCol>
                </BRow>
              </BCol>
            </BRow>
          </BCard>
        </div>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BOverlay, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { IDR } from '@/libs/currency'
import { mapState } from 'vuex'
import MonthlyPicker from 'vue-monthly-picker'
import {
  END_DATE_OF_MONTH,
  LABELMONTH,
  START_DATE_OF_MONTH,
} from '@/libs/filterDate'
import ApexChart from 'vue-apexcharts'
import {
  columnShippingExpedition, optionChartShipping, optionExpedition,
} from '../config'

export default {
  components: {
    BRow, BCol, BCard, BTable, BOverlay, MonthlyPicker, BFormSelect, BSpinner, ApexChart,
  },
  data() {
    return {
      items: [],
      fields: columnShippingExpedition,
      loading: {
        shipping: false,
        chart: false,
      },
      IDR,
      totalShipping: 0,
      monthlabel: LABELMONTH,
      filter: {
        shipping: this.$moment(),
        delivery: this.$moment(),
      },
      optionExpedition,
      payment: '1',
      expedition: 'JNE',
      optionChartShipping,
      seriesChartShipping: [],
      totalOrder: 0,
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
    maxDatePicker() { return this.$moment().endOf('month') },
    payValueDetail() {
      return this.optionExpedition.find(o => o.value === +this.payment).valueDetail
    },
  },
  watch: {
    'filter.shipping': {
      handler() { this.getShippingExpedition() },
    },
    'filter.delivery': {
      handler() { this.getChart() },
    },
    payment: {
      handler() { this.getShippingExpedition() },
    },
    expedition: {
      handler() { this.getChart() },
    },
  },
  mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.getShippingExpedition()
    this.getChart()
  },
  methods: {
    async getShippingExpedition() {
      this.loading.shipping = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.shipping),
        end_date: END_DATE_OF_MONTH(this.filter.shipping),
        payment_option: this.payment,
      }
      const url = '/v1/admin/dashboard/topShippingExpeditors'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data, total_shipping_cost } = res.data
          this.items = data
          const dataTotal = data.map(i => i.total_order)
          this.totalOrder = dataTotal.reduce((acc, curr) => acc + curr, 0)
          this.loading.shipping = false
          // eslint-disable-next-line camelcase
          this.totalShipping = total_shipping_cost
        })
        .catch(err => {
          this.loading.shipping = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: err.response.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async getChart() {
      this.loading.chart = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.delivery),
        end_date: END_DATE_OF_MONTH(this.filter.delivery),
        expedition_option: this.expedition,
      }
      const url = '/v1/admin/dashboard/shippingPerformancePerExpedition'
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loading.chart = false
          this.seriesChartShipping = [
            {
              name: 'Total',
              data: data.total.map(e => e.total),
            },
            {
              name: 'Non - COD',
              data: data.non_cod.map(e => e.total_noncod),
            },
            {
              name: 'COD',
              data: data.cod.map(e => e.total_cod),
            },
          ]
          this.optionChartShipping = {
            ...this.optionChartShipping,
            xaxis: {
              ...this.optionChartShipping.xaxis,
              categories: data.total.map(e => e.day),
            },
          }
        })
        .catch(err => {
          this.loading.chart = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: err.response.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    handleDetailButton(shipping) {
      if (shipping === 'IDEXPRESS') return true
      if (shipping === 'SAP') return true
      if (shipping === 'JNE') return true
      return false
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
